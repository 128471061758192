import api_rel from '../../config/api_rel';
class RelatoriosController {
  /* eslint-disable */
  async series ( req ) {
    try {
      let response = await api_rel.post( `/seriesdocumento`, req );
      response.data.forEach( item => { item.text = item.PEDF_SERIE });
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
  async RelatorioVendaDeServicos ( req ) {
    try {
      let response = await api_rel.post( `/relatoriovendasservico`, req );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
  async RelatorioVendaDeProdutos ( req ) {
    try {
      let response = await api_rel.post( `/relatoriovendasprodutos`, req );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
  async RelatorioCurvaDeProdutos ( req ) {
    try {
      let response = await api_rel.post( `/curvaprodutos/reports`, req );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  }
  async RelatorioDeVendas ( req ) {
    try {
      let response = await api_rel.post( `/vendas/reports`, req );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  }
  async RelatorioRecebimentos ( req ) {
    try {
      let response = await api_rel.post( `/recebimentos/reports`, req );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  }
}
export default new RelatoriosController();