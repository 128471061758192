const produtosRoutes = [
    {
        path: '/produtos',
        name: 'IndexProduto',
        meta: {
          requiresAuth: true,
          title: "Produtos",
        },
        component: () => import('../../views/produto/IndexProduto.vue')
      },
      {
        path: '/produtos/inserir',
        name: 'InsertProduto',
        meta: {
          requiresAuth: true,
          title: "Cadastro de produto",
        },
        component: () => import('../../views/produto/InsertProduto.vue')
      },
      {
        path: '/produtos/alterar',
        name: 'AlterProduto',
        meta: {
          requiresAuth: true,
          title: "Editar produto",
        },
        component: () => import('../../views/produto/AlterProduto.vue')
      }
]
export default produtosRoutes;