import api from "../config/api";
class LocaisController {
  /* eslint-disable */
  async getLength ( req, res ) {
    try {
      let total = await api.post( '/localestoque/registros', req );
      let response = res;
      response.total = total.data[ 0 ].TOT_REGISTROS;
      return response;
    } catch ( error ) {
      throw error;
    }
  }
  async index ( req ) {
    try {
      let response = await api.post( "/localestoque/filtro", req );
      if ( !response.data.msg ) {
        response.data.forEach(( item, index ) => {
          item.ORDEM = index % 2 === 0 ? 0 : 1;
          item.id = item.LOCA_ID;
          item.text = item.LOCA_DESCRICAO;
        }); 
      }
      return await this.getLength( req, response.data );
    } catch ( error ) {
      throw error;
    }
  }
  async created(req) {
    try {
      let response = await api.post(`/localestoque/`, req);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async updated(req) {
    try {
      let response = await api.put(`/localestoque/${req.LOCA_ID}`, req);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async delete(req) {
    try {
      let response = await api.delete(`/localestoque/${req.LOCA_ID}`);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async search(req) {
    try {
      let response = await api.get(`/localestoque/pesquisar/${req.LOCA_ID}`);
      if(!response.data.msg){
        response.data.forEach(item => {
          item.id = item.LOCA_ID;
          item.text = item.LOCA_DESCRICAO;
        });
      }
      return response.data;
    } catch(error) {
      throw error
    }
  }
}
export default new LocaisController();
