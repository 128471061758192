import axios from 'axios'
const urlBackend = process.env.VUE_APP_BACKEND

const api = axios.create({
  baseURL: urlBackend,
  consumer: 'FrontWeb',
  timeout: 15000,
});

api.interceptors.response.use((response) => {
  return response
}, (error) => {
  if(error.code === 'ECONNABORTED') {
    throw { msg: "Tempo de Solicitação Esgotado", code: 408 }
  } else {
    throw { msg: error?.response?.data?.msg, code: error.response?.status }
  }
});

export default api