const subGruposRoutes = [
  {
    path: '/subgrupos',
    name: 'IndexSubGrupos',
    meta: {
      requiresAuth: true,
      title: "Sub-Grupos",
    },
    component: () => import('../../views/subGrupos/IndexSubGrupos.vue')
  },
  {
    path: '/subgrupos/inserir',
    name: 'InsertSubGrupos',
    meta: {
      requiresAuth: true,
      title: "Cadastro de Sub-Grupos",
    },
    component: () => import('../../views/subGrupos/InsertSubGrupos.vue')
  },
  {
    path: '/subgrupos/alterar',
    name: 'AlterSubGrupos',
    meta: {
      requiresAuth: true,
      title: "Editar Sub-Grupo",
    },
    component: () => import('../../views/subGrupos/AlterSubGrupos.vue')
  },
]
export default subGruposRoutes;