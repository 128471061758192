const unidadesRoutes = [
  {
    path: '/unidades/inserir',
    name: 'InsertUnidades',
    meta: {
      requiresAuth: true,
      title: "Cadastro de unidade",
    },
    component: () => import('../../views/unidades/InsertUnidades.vue')
  },
  {
    path: '/unidades/alterar',
    name: 'AlterUnidades',
    meta: {
      requiresAuth: true,
      title: "Alterar unidade",
    },
    component: () => import('../../views/unidades/AlterUnidades.vue')
  },
  {
    path: '/unidades',
    name: 'IndexUnidades',
    meta: {
      requiresAuth: true,
      title: "Unidades",
    },
    component: () => import('../../views/unidades/IndexUnidades.vue')
  }
]
export default unidadesRoutes;