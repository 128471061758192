const setoresServicoRoutes = [
  {
    path: '/setoresservico',
    name: 'IndexSetoresServico',
    meta: {
      requiresAuth: true,
      title: "Setores de Serviço",
    },
    component: () => import('../../views/setoresServico/IndexSetoresServico.vue')
  },
  {
    path: '/setoresservico/inserir',
    name: 'InsertSetoresServico',
    meta: {
      requiresAuth: true,
      title: "Cadastrar Setor de Serviço",
    },
    component: () => import('../../views/setoresServico/InsertSetoresServico.vue')
  },
  {
    path: '/setoresservico/alterar',
    name: 'AlterSetoresServico',
    meta: {
      requiresAuth: true,
      title: "Editar Setor de Serviço",
    },
    component: () => import('../../views/setoresServico/AlterSetoresServico.vue')
  },
]
export default setoresServicoRoutes;