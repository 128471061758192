import api from '../config/api';

class ProdutoController {
  /* eslint-disable */
/////////////////////////////////////*PRINCIPAL*/////////////////////////////////////////
    async getLength(req, res){
      try {
        let total = await api.post(`/produtos/registros/${req.EMP_ID}`, req);
        let response = res;
        response.total = total.data[0].TOT_REGISTROS;
        return response;
      } catch(error) {
        throw error
      }
    }
    async index(req) {
      try {
        if(!req.LIMIT){ req.LIMIT = 20 }
        let response = await api.post(`/produtos/${req.EMP_ID}`, req);
        if(!response.data.msg){
          response.data.forEach((item, index) => {
            item.ORDEM = index % 2 === 0 ? 0 : 1;
            item.text = item.PROD_DESCRICAO;
            item.id = item.PROD_ID;
          });
        }
      return await this.getLength(req, response.data);
    } catch(error) {
      throw error
    }
    }
    async save(req) {
      try {
        let response = await api.post('/produtos/', req);
        return response.data;
      } catch(error) {
        throw error
      }
    }
    async edit(req) {
      try {
        let response = await api.put(`/produtos/${req.PROD_ID}/${req.PROD_EMP_ID}`, req);
        return response.data;
      } catch(error) {
        throw error
      }
    }
    async delete(req) {
      try {
        let response = await api.delete(`/produtos/${req.PROD_ID}/${req.PROD_EMP_ID}`);
        return response.data;
      } catch(error) {
        throw error
      }
    }
    async listView(req) {
      let response = await api.get(`/produtos/${req.ID}/${req.EMP_ID}`);
      return response.data;
    }
    async search(req) {
      if (req && req != '') {
        let response = await api.get(`/produtos/pesquisar/${req.FILTRO}/${req.EMP_ID}`);
        if(response.data.length > 0){
          response?.data.forEach((item) => {
            item.text = item.PROD_DESCRICAO;
            item.id = item.PROD_ID;
          });
        }
        return response.data;
      }
      return [];
    }
/////////////////////////////////////*IMAGENS*/////////////////////////////////////////
    updatedImage(data) {
      let formData = new FormData();
      formData.append("file", data.IMAGE);
      return api.post(`/produtos/imagem/${data.ID}/${data.EMP_ID}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    }
    async deleteImage(req) {
        let response = await api.delete(`/produtos/imagem/${req.ID}/${req.EMP_ID}/${req.NOME_IMG}`);
        return response.data;
    }
/////////////////////////////////////*INFORMAÇÕES*/////////////////////////////////////////
    async indexProdInfo(req) {
      let response = await api.post(`/produtosinf/${req.PROD}/${req.PROD_EMP}`)
      return response.data;
    }
    async saveProdInfo(req) {
      let response = await api.post('/produtosinf', req);
      return response.data;
    }
    async editProdInfo(req) {
      let response = await api.put(`/produtosinf/${req.PROD}/${req.PROD_EMP}/${req.ID}`, req);
      return response.data;
    }
    async deleteProdInfo(req) {
      let response = await api.delete(`/produtosinf/${req.PROD}/${req.PROD_EMP}/${req.ID}`);
      return response.data
    }
    async replicarProdInfo(req) {
      let response = await api.get(`/produtosinfreplicador/${req.PROI_ID}/${req.PROD_EMP_ID}/${req.USR_USUARIO}/${req.PROD_ID}`);
      return response.data;
    }
/////////////////////////////////////*CÓDIGO DE BARRAS*/////////////////////////////////////////
    async indexProdCod(req) {
      let response = await api.post(`/produtoscbar/${req.PROD}/${req.PROD_EMP}`)
      return response.data;
    }
    async saveProdCod(req) {
      let response = await api.post('/produtoscbar', req);
      return response.data;
    }
    async editProdCod(req) {
      let response = await api.put(`/produtoscbar/${req.PROD}/${req.PROD_EMP}/${req.ID}`, req);
      return response.data;
    }
    async deleteProdCod(req) {
      let response = await api.delete(`/produtoscbar/${req.PROD}/${req.PROD_EMP}/${req.ID}`);
      return response.data
    }
/////////////////////////////////////*MATÉRIA PRIMA*/////////////////////////////////////////
    async indexMateriaPrima(req) {
      let response = await api.post(`/materiaprima/${req.PROD_ID}/${req.EMP_ID}`)
      return response.data;
    }
    async saveMateriaPrima(req) {
      let response = await api.post('/materiaprima', req);
      return response.data;
    }
    async editMateriaPrima(req) {
      let response = await api.put(`/materiaprima/${req.PROD_ID}/${req.EMP_ID}/${req.ID}`, req);
      return response.data;
    }
    async deleteMateriaPrima(req) {
      let response = await api.delete(`/materiaprima/${req.PROD_ID}/${req.EMP_ID}/${req.ID}`);
      return response.data
    }
/////////////////////////////////////*FICHA ESTOQUE*/////////////////////////////////////////
    async getLengthFichaEstoque(req, res){
      let total = await api.post(`/fichaestoque/registros/${req.PROD}/${req.PROD_EMP}`, req);
      let response = res;
      response.total = total.data[0].TOT_REGISTROS;
      return response.data;
    }
    async indexFichaEstoque(req) {
      let response = await api.post(`/fichaestoque/${req.PROD}/${req.PROD_EMP}`, req);
      return await this.getLengthFichaEstoque(req, response);
    }
/////////////////////////////////////*FORNECEDOR*/////////////////////////////////////////
    async getLengthFornecedor(req, res){
      let total = await api.post(`/produtosfornecedor/registros/${req.PROD_ID}/${req.PROD_EMP_ID}`, req);
      let response = res;
      response.total = total.data[0].TOT_REGISTROS;
      return response.data;
    }
    async indexFornecedor(req) {
      let response = await api.post(`/produtosfornecedor/${req.PROD_ID}/${req.PROD_EMP_ID}`, req);
      return await this.getLengthFornecedor(req, response);
    }
    async saveFornecedor(req) {
      let response = await api.post('/produtosfornecedor', req);
      return response.data;
    }
    async editFornecedor(req) {
      let response = await api.put(`/produtosfornecedor/${req.PRFR_PROD_ID}/${req.PRFR_PROD_EMP_ID}/${req.PRFR_FORN_ID}/${req.PRFR_REF_ID}`, req);
      return response.data;
    }
    async deleteFornecedor(req) {
      let response = await api.delete(`/produtosfornecedor/${req.PROD_ID}/${req.PROD_EMP_ID}/${req.FORN_ID}/${req.REF}`);
      return response.data
    }
/////////////////////////////////////*HISTORICO*/////////////////////////////////////////
    async getLengthHistorico(req, res){
      let total = await api.post(`/produtoshistorico/registros/${req.PROD_ID}/${req.PROD_EMP_ID}`, req);
      let response = res;
      response.total = total.data[0].TOT_REGISTROS;
      return response.data;
    }
    async indexHistorico(req) {
      let response = await api.post(`/produtoshistorico/${req.PROD_ID}/${req.PROD_EMP_ID}`, req);
      return await this.getLengthHistorico(req, response);
    }
    async saveHistorico(req) {
      let response = await api.post('/produtoshistorico', req);
      return response.data;
    }
    async editHistorico(req) {
      let response = await api.put(`/produtoshistorico/${req.PROD_ID}/${req.PROD_EMP_ID}/${req.ID}`, req);
      return response.data;
    }
    async deleteHistorico(req) {
      let response = await api.delete(`/produtoshistorico/${req.PROD_ID}/${req.PROD_EMP_ID}/${req.ID}`);
      return response.data
    }
/////////////////////////////////////*GRADE*/////////////////////////////////////////
    async getLengthGrade(req, res){
      let total = await api.post(`/produtosgrade/registros/${req.PROD_ID}/${req.PROD_EMP_ID}`, req);
      let response = res;
      response.total = total.data[0].TOT_REGISTROS;
      return response.data;
    }
    async indexGrade(req) {
      let response = await api.post(`/produtosgrade/${req.PROD_ID}/${req.PROD_EMP_ID}`, req);
      return await this.getLengthGrade(req, response);
    }
    async saveGrade(req) {
      let response = await api.post('/produtosgrade', req);
      return response.data;
    }
    async editGrade(req) {
      let response = await api.put(`/produtosgrade/${req.PROD_ID}/${req.PROD_EMP_ID}/${req.ID}`, req);
      return response.data;
    }
    async deleteGrade(req) {
      let response = await api.delete(`/produtosgrade/${req.PROD_ID}/${req.PROD_EMP_ID}/${req.ID}`);
      return response.data
    }
    async searchGrade(req) {
      let response = await api.get(`/produtosgrade/pesquisar/${req.PROD_ID}/${req.PROD_EMP_ID}/${req.ID}`);
      return response.data;
    }
    async replicarGarde(req) {
      let response = await api.get(`/produtosgradereplicador/${req.PROD_ID}/${req.PROD_EMP_ID}/${req.USR_USUARIO}/${req.PRGR_ID}`);
      return response.data;
    }
////////////////////////////////////////////////////////////////////////////////////////////////
    async getLengthPintura(req, res){
      let total = await api.post(`/produtopintura/registros/${req.PROD_ID}/${req.PROD_EMP_ID}`, req);
      let response = res;
      response.total = total.data[0].TOT_REGISTROS;
      return response.data;
    }
    async indexPintura(req) {
      let response = await api.post(`/produtopintura/${req.PROD_ID}/${req.PROD_EMP_ID}`, req);
      return await this.getLengthPintura(req, response);
    }
    async savePintura(req) {
      let response = await api.post('/produtopintura', req);
      return response.data;
    }
    async deletePintura(req) {
      let response = await api.delete(`/produtopintura/${req.PROD_ID}/${req.PROD_EMP_ID}`);
      return response.data
    }
////////////////////////////////////////////////////////////////////////////////////////////////
    async saldoFiscal(req) {
      let response = await api.get(`/produtosestoquebd/${req.ID}/${req.EMP_ID}`);
      return response.data;
    }
    async estoqueAlocado(req) {
      let response = await api.get(`/produtosqtdalocados/${req.ID}/${req.EMP_ID}`);
      return response.data
    }
    async indexAlocado(req) {
      let response = await api.get(`/produtosalocados/${req.ID}/${req.EMP_ID}/${req.USR_ID}`);
      return response.data;
    }
    async custoMedio(req) {
      let response = await api.get(`/produtoscustomedio/${req.ID}/${req.EMP_ID}`);
      return response.data;
    }
    async ultimoCusto(req) {
      let response = await api.get(`/produtosutimocusto/${req.ID}/${req.EMP_ID}`);
      return response.data;
    }
    async saldoEstoque(req) {
      let response = await api.post(`/saldoestoque`, req);
      return response.data;
    }
    async recalculaEstoque(req) {
      let response = await api.get(`/produtosrecalculaestoque/${req.ID}/${req.EMP_ID}`);
      return response.data;
    }
////////////////////////////////////////////////////////////////////////////////////////////////
    async DuplicarProduto ( req ) {
      let response = await api.post( `/produtoduplicar/${ req.PROD_ID }/${ req.PROD_EMP_ID }/${ req.USUARIO }` );
      return response.data;
    } 
}
export default new ProdutoController();