const locaisRoutes = [
  {
    path: '/locais',
    name: 'IndexLocais',
    meta: {
      requiresAuth: true,
      title: "Locais",
    },
    component: () => import('../../views/locais/IndexLocais.vue')
  },
  {
    path: '/locais/inserir',
    name: 'InsertLocais',
    meta: {
      requiresAuth: true,
      title: "Cadastro de Locais",
    },
    component: () => import('../../views/locais/InsertLocais.vue')
  },
  {
    path: '/locais/alterar',
    name: 'AlterLocais',
    meta: {
      requiresAuth: true,
      title: "Editar Local",
    },
    component: () => import('../../views/locais/AlterLocais.vue')
  },
]
export default locaisRoutes;