import api from '../config/api';
class ServicosController {
  /* eslint-disable */
  async getLength(req, res) {
    try {
      let total = await api.post(`/servico/registros/${req.EMP_ID}`, req);
      let response = res;
      response.total = total.data[0].TOT_REGISTROS;
      return response;
    } catch(error) {
      throw error
    }
  }
  async index(req) {
    try {
      let response = await api.post(`/servico/${req.EMP_ID}`, req);
      if(!response.data.msg) {
        response.data.forEach((item, index) => {
          item.ORDEM = index % 2 === 0 ? 0 : 1;
          item.id =  item.SERV_ID;
          item.text = item.SERV_DESCRICAO;
        });
      }
      return await this.getLength(req, response.data);
    } catch(error) {
      throw error
    }
  }
  async created(req) {
    try {
      let response = await api.post('/servico/', req);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async updated(req) {
    try {
      let response = await api.put(`servico/${req.SERV_ID}/${req.SERV_EMP_ID}`, req);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async delete(req) {
    try {
      let response = await api.delete(`/servico/${req.SERV_ID}/${req.SERV_EMP_ID}`);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async search(req) {
    try {
      if (!req.LIMIT) { req.LIMIT = 20 }
      let response = await api.get(`/servico/pesquisar/${req.FILTRO}/${req.EMP_ID}`, req);
      if(!response.data.msg) {
        response.data.forEach(item => {
          item.id =  item.SERV_ID;
          item.text = item.SERV_DESCRICAO;
        });
      }
      return response?.data;
    } catch(error) {
      throw error
    }
  }
  // ordemservicoservico
  async getServiceDataTable(req) {
    try {
      let response = await api.post(`/ordemservicoservico/${req.NROS}/${req.EMP_ID}/OS`, req);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async addService(req) {
    try {
      let response = await api.post(`/ordemservicoservico`, req);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async edit(req, dadosURL) {
    try {
      let response = await api.put(`/ordemservicoservico/${dadosURL.NROS}/${dadosURL.EMP_ID}/OS/${dadosURL.id}`, req);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async deleteService(req) {
    try {
      let response = await api.delete(`/ordemservicoservico/${req.NUM_OS}/${req.EMP_ID}/OS/${req.NUM_SERV}`);
      return response.data
    } catch(error) {
      throw error
    }
  }
  // ordemservicoservicoleituras
  async getLeitura(req) {
    try {
      let response = await api.post(`/ordemservicoservicoleituras/${req.NROS}/${req.EMP_ID}/OS`, req);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async addLeitura(req) {
    try {
      let response = await api.post(`/ordemservicoservicoleituras`, req);
      return response.data;
    } catch(error) {
      throw error
    }
  }
  async updateLeitura(req, dadosURL) {
    try {
      let response = await api.put(`/ordemservicoservicoleituras/${dadosURL.NROS}/${dadosURL.EMP_ID}/OS`, req);
      return response.data;
    } catch(error) {
      throw error
    }
  }
}
export default new ServicosController();