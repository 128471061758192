const servicosRoutes = [
  {
    path: '/servicos',
    name: 'IndexServicos',
    meta: {
      requiresAuth: true,
      title: "Serviços",
    },
    component: () => import('../../views/servicos/IndexServicos.vue')
  },
  {
    path: '/servicos/inserir',
    name: 'InsertServicos',
    meta: {
      requiresAuth: true,
      title: "Cadastro de serviço",
    },
    component: () => import('../../views/servicos/InsertServicos.vue')
  },
  {
    path: '/servicos/alterar',
    name: 'AlterServicos',
    meta: {
      requiresAuth: true,
      title: "Editar serviço",
    },
    component: () => import('../../views/servicos/AlterServicos.vue')
  }
]
export default servicosRoutes;