import Vue from 'vue'
import Vuex from 'vuex'
import VueCookies from 'vue-cookies'
import { cript } from '../functions/criptografia'
const env = require('@/config/env.json');
Vue.use(Vuex)
Vue.use(VueCookies)
let style = ""
if(VueCookies.get('style')){
  style = VueCookies.get('style');
}
else{
  style = cript.encryptData(JSON.stringify(env));
}

export default new Vuex.Store({ 
    state: {
      token: null,
      id: null,
      user: null,
      style: style,
      empresa: null,
      credenciais: null,
      cidades: [],
      favoritos: [],
      filters: {
        soli_id: '',
        dataInicial: '',
        dataFinal: '',
        soli_sopr_id: '',
        soli_emp_id: '',
        soli_tipo: '',
        soli_prioridade: '',
        soli_usr_requester: '',
        soli_ultimo_usr: '',
        soli_situ_filho: '',
        soli_situacao: 1,
        req: { ADICIONAIS: { status: 7 }},
      },
      filters_advanced: {
        tela_name: "",
        filters: [],
      },
      produto: { produtoID: null },
      ncm: { ncmID: null },
      changesNotSaved: false,
      clienteContasAtualizadas: false,
      mini: false,
    },
    mutations: {
      //token
      setToken(state, token) {
        state.token = token
      },
      deleteToken(state){
        state.token = null,
        state.id = null,
        state.user = null,
        state.empresa = null,
        state.favoritos = []
      },
      deleteCredenciais(state){
        state.credenciais = null
      },
      //Cli ID
      setID(state, id){
        state.id = id
      },
      setUser(state, user){
        state.user = user
      },
      //empresa 
      setEmpresa(state, empresa){
        state.empresa = empresa
      },

      //styles
      setStyle(state, style){
        state.style = style
      },
      //credenciais
      setCredenciais(state, credenciais){
        state.credenciais = credenciais
      },
      setCidades(state, cidades){
        state.cidades = cidades
      },
      setFavoritos(state, favoritos){
        state.favoritos = favoritos
      },
      setFilters(state, filters) { state.filters = filters }, // helpDesk
      setFilters_Advanced( state, filters_advanced ) { state.filters_advanced = filters_advanced }, // filtros avançados
      setProduto(state, produto) { state.produto = produto }, // produto
      setNcm(state, ncm) { state.ncm = ncm },                 // NCM
      setNotSaved(state, changesNotSaved) { state.changesNotSaved = changesNotSaved },
      setCLienteContas(state, atualizada) {state.clienteContasAtualizadas = atualizada },
      setMini(state, mini) { state.mini = mini },
    },
    actions: {
      saveToken({ commit }, token) {
        commit('setToken', token)
        Vue.$cookies.set('token', token);
      },
      logout({ commit }) {
        commit('deleteToken')
        Vue.$cookies.remove('token');
        Vue.$cookies.remove('id');
        Vue.$cookies.remove('user');
      },
      saveID({ commit }, id) {
        commit('setID', id)
        Vue.$cookies.set('id', id);
      },
      saveUser({ commit }, user) {
        commit('setUser', user)
        Vue.$cookies.set('user', user);
      },
      saveEmpresa({ commit }, empresa) {
        commit('setEmpresa', empresa)
        Vue.$cookies.set('empresa', empresa);
      },
      saveStyle({ commit }, style) {
        commit('setStyle', style)
        Vue.$cookies.set('style', style);
      },
      saveCredenciais({ commit }, credenciais) {
        commit('setCredenciais', credenciais)
        Vue.$cookies.set('credenciais', credenciais);
      },
      deleteCredenciais({ commit }) {
        commit('deleteCredenciais')
        Vue.$cookies.remove('credenciais');
      },
      saveCidades({ commit }, cidades) {
        commit('setCidades', cidades)
      },
      saveFavoritos({ commit }, favoritos) {
        commit('setFavoritos', favoritos)
      },
    },
    getters: {
      isAuthenticated: state => !!state.token,
    }
  })
  
  