const marcaProdutoRoutes = [
    {
        path: '/marcaprodutos',
        name: 'IndexMarcaProdutos',
        meta: {
          requiresAuth: true,
          title: "Marca de Produtos",
        },
        component: () => import('../../views/marcaProdutos/IndexMarcaProdutos.vue')
      },
      {
        path: '/marcaprodutos/inserir',
        name: 'InsertMarcaProdutos',
        meta: {
          requiresAuth: true,
          title: "Cadastro Marca de Produtos",
        },
        component: () => import('../../views/marcaProdutos/InsertMarcaProdutos.vue')
      },
      {
        path: '/marcaprodutos/alterar',
        name: 'AlterMarcaProdutos',
        meta: {
          requiresAuth: true,
          title: "Edição Marca de Produtos",
        },
        component: () => import('../../views/marcaProdutos/AlterMarcaProdutos.vue')
      },
]
export default marcaProdutoRoutes;